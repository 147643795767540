<template>
  <div class="fancy-feature-twelve pt-250 md-pt-50" id="about">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-10 col-md-8 m-auto">
            <div class="title-style-six text-center">
              <h2>It's easy and <span>free</span>!</h2>
            </div>
            <!-- /.title-style-six -->
          </div>
        </div>

        <div class="row justify-content-center pt-50 md-pt-20">
          <div
            v-for="item in featureData"
            :key="item.id"
            class="col-lg-4 col-md-6"
          >
            <div class="block-style-seventeen mt-40">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                <i :class="item.icon" alt="" />
              </div>
              <div class="static-text">
                <h3>{{ item.title }}</h3>
                <p>{{ item.subtitle }}</p>
              </div>
              <div class="hover-text">{{ item.hover_text }}</div>
            </div>
            <!-- /.block-style-seventeen -->
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
    <img
      src="../../assets/images/shape/151.svg"
      alt=""
      class="shapes shape-one"
    />
  </div>
</template>

<script>
export default {
  name: "FeatureArea",
  data() {
    return {
      featureData: [
        {
          id: 1,
          icon: 'fa fa-comments fa-lg',
          title: "Easy to Use",
          subtitle: "NikuNiku900 talks to you through Discord.",
          hover_text: "Use slash-commands to interact with NikuNiku900.",
        },
        {
          id: 2,
          icon: 'fa fa-clock-o fa-lg',
          title: "Fast & Free",
          subtitle: "Generate images in seconds, and it's free!",
          hover_text: "No need for an expensive GPU or a paid subscription.",
        },
        {
          id: 3,
          icon: 'fa fa-mobile fa-lg',
          title: "Use Anywhere",
          subtitle: "Any device that can run Discord can use NikuNiku900.",
          hover_text: "Just join our server - no downloads required!",
        },
      ],
    };
  },
};
</script>
