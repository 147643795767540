import {createRouter, createWebHistory} from 'vue-router'
import Home from '../pages/home';


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'NikuNiku900 || AI Art for All',
        },
    },
    {
        path: '/tos',
        name: 'TOS',
        component: () => import('../pages/tos'),
        meta: {
            title: 'NikuNiku900 || Terms of Service',
        },
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('../pages/privacy'),
        meta: {
            title: 'NikuNiku900 || Privacy Policy',
        },
    }
   
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
