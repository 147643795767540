<template>
  <div class="fancy-short-banner-seven pt-250 md-pt-100" id="invite">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-11 m-auto">
          <div class="title-style-six text-center">
            <h2>Want Niku on your own server? <span>Invite</span> her for free!</h2>
          </div>
          <!-- /.title-style-six -->
          <a
            href="https://discord.com/api/oauth2/authorize?client_id=1073805915649740830&permissions=0&scope=bot%20applications.commands"
            class="d-flex align-items-center ios-button gr-bg-one hover-reverse-gr-bg-one"
          >
            <img src="../../assets/images/icon/discord.svg" width="40px" alt="" class="icon" />
            <div>
              <span>Invite NikuNiku900</span>
              <strong>on Discord</strong>
            </div>
          </a>
        </div>
      </div>
      <div class="screen-meta js-tilt">
        <img
          src="../../assets/images/niku/bottom_niku.png"
          alt=""
          class="m-auto"
        />
        <img
          src="../../assets/images/shape/165.svg"
          alt=""
          class="shapes shape-four"
        />
        <img
          src="../../assets/images/shape/166.png"
          alt=""
          class="shapes shape-five"
        />
      </div>
    </div>
    <!-- /.container -->
    <img
      src="../../assets/images/shape/162.svg"
      alt=""
      class="shapes shape-one"
    />
    <img
      src="../../assets/images/shape/163.svg"
      alt=""
      class="shapes shape-two"
    />
    <img
      src="../../assets/images/shape/164.svg"
      alt=""
      class="shapes shape-three"
    />
  </div>
</template>

<script>
export default {
  name: "ShortBanner",
};
</script>
