<template>
  <footer class="theme-footer-six">
    <div class="inner-container">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-4">
            <div class="logo"><router-link to="/">
              <img src="../../../assets/images/logo/nikulogo2.png" alt=""></router-link>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="title">Find us on Social Media</div>
            <ul class="d-flex justify-content-center social-icon">
              <li><a href="https://github.com/Schneewolf-Labs"><i class="fa fa-github" aria-hidden="true"></i></a></li>
              <li><a href="https://twitch.com/nikuniku900"><i class="fa fa-twitch" aria-hidden="true"></i></a></li>
              <li><a href="https://twitter.com/nikuniku900"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="https://www.linkedin.com/company/schneewolf-labs/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
          </div>
          <div class="col-lg-4">
            <div class="text-center"><a href="/tos" class="email">Terms of Service</a></div>
            <div class="text-center"><a href="/privacy" class="email">Privacy Policy</a></div>
          </div>
        </div>
        <p class="copyright"><a href="https://schneewolflabs.com">Copyright @2023 Schneewolf Labs</a></p>
      </div>
      <img src="../../../assets/images/shape/167.png" alt="" class="shapes shape-one">
    </div> <!-- /.inner-container -->
  </footer> <!-- /.theme-footer-six -->
</template>

<script>
export default {
  name: 'FooterSix'
}
</script>