<template>
    <div class="fancy-feature-twelve pt-250 md-pt-50" id="watch">
        <div class="bg-wrapper">
            <div class="container">
                <div class="title-style-six text-center">
                    <h2>Watch Niku <span>Live</span>!</h2>
                </div>
                <iframe
                    src="https://player.twitch.tv/?channel=nikuniku900&parent=nikuniku900.com"
                    height="500"
                    width="100%"
                    allowfullscreen>
                </iframe>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "WatchArea",
    components: {
    },
    data() {
        return {
            channel: 'nikuniku900',
        }
    }
};
</script>