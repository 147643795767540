<template>
  <div
    class="pricing-section-five pt-100 pt-150 md-pt-150 sm-pt-80 md-pt-80"
    id="pricing"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 order-lg-last">
          <div class="title-style-six ps-5 md-p0">
            <h6 style="font-size: 15px">VIP Pricing</h6>
            <h2>Subscribe with <span>Discord</span>!</h2>
            <p class="text-xs">
              Safe, simple, and secure. <br> Enjoy a free 1 week trial!
            </p>
          </div>
          <!-- /.title-style-six -->
        </div>
        <div class="col-lg-7 position-relative">
          <div class="pricing-table-area-five md-mt-60">
            <img
              src="../../assets/images/icon/88.png"
              alt=""
              class="shapes shape-one"
            />
            <img
              src="../../assets/images/shape/160.png"
              alt=""
              class="shapes shape-two"
            />
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="pr-table-wrapper active js-tilt">
                  <img
                    src="../../assets/images/icon/85.svg"
                    alt=""
                    class="icon"
                  />
                  <div class="pack-name">VIP Elite</div>
                  <ul class="pr-feature">
                    <li>Private Messages</li>
                    <li>Queue Priority</li>
                    <li>Exclusive Support</li>
                  </ul>
                  <div class="price">$4.99</div>
                  <div class="trial-text">per month</div>
                  <a href="https://discord.com/servers/nikunikunation-1070826112915083328" class="trial-button hover-reverse-gr-bg-one"
                    >Subscribe</a
                  >
                </div>
                <!-- /.pr-table-wrapper -->
              </div>
              <div class="col-md-6">
                <div class="pr-table-wrapper js-tilt sm-mt-80">
                  <img
                    src="../../assets/images/shape/159.svg"
                    alt=""
                    class="popular-badge"
                  />
                  <img
                    src="../../assets/images/icon/86.svg"
                    alt=""
                    class="icon"
                  />
                  <div class="pack-name">VIP</div>
                  <ul class="pr-feature">
                    <li>Unlimited Generations</li>
                    <li>No Watermarks</li>
                    <li>More Options and Features</li>
                    <li>Priority Support</li>
                  </ul>
                  <div class="price">$2.99</div>
                  <div class="trial-text">per month</div>
                  <a
                    href="https://discord.com/servers/nikunikunation-1070826112915083328"
                    class="trial-button hover-reverse-gr-bg-one gr-bg-one"
                    >Subscribe</a
                  >
                </div>
                <!-- /.pr-table-wrapper -->
              </div>
            </div>
          </div>
          <!-- /.pricing-table-area-five -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingArea",
};
</script>
